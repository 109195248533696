/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import AuthProvider from "./src/components/authProvider";

import './src/styles/globals.css'
import './src/styles/fancybox.css'
import CurrencyProvider from "./src/components/currencyProvider";

export function wrapRootElement({element}) {
    return (
      <AuthProvider>
        <CurrencyProvider>
          {element}
        </CurrencyProvider>
      </AuthProvider>
    );
  }