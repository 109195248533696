import { graphql, useStaticQuery } from "gatsby";
import React, { Children, createContext, useContext, useState } from "react";

const CurrencyContext = createContext<any>({});

export function useCurrency() {
  return useContext(CurrencyContext);
}

export default function CurrencyProvider({children}) {
  const data = useStaticQuery(graphql`
    query {
      currency {
        rates {
          COP
          PEN
          SEK
          GBP
          MXN
          USD
          EUR
          JPY
          HKD
          PYG
        }
      }
    }
  `)

  return (
    <CurrencyContext.Provider
      value={data.currency}
    >
      {children}
    </CurrencyContext.Provider>
  )
}